import React from "react";

export default function Maintenance() {
    return (
        <div className="h-screen flex items-center justify-center text-center px-4 max-md:px-2 font-[Montserrat]">
            <div className="w-[70%] max-md:w-full">
                <h1 className="text-6xl font-bold max-md:text-3xl">
                    We're currently under maintenance.
                </h1>
                <h3 className="text-xl leading-loose max-md:text-base">
                    We are doing some updates on our site, and we need to be offline for a while. 
                    We will be back with you shortly!
                </h3>
                
                <p className="mt-2 max-md:text-base">Send us a message and we will get back to you asap!</p>
                <a
                    href="mailto:mradnane.amrouss@gmail.com"
                    className="hover:text-blue-900 duration-700 underline block  max-md:text-base"
                >
                    mradnane.amrouss@gmail.com
                </a>
            </div>
        </div>
    );
}